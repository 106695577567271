import { useCallback, useMemo, useRef, useState } from 'react';
import gsap from 'gsap';

//* Components
import Icon from '../Icon';
import Text from '@/components/common/Text';
import Image from '@/components/common/Image';
import CustomLink from '@/components/common/CustomLink';

//* Style
import ButtonStyle from './style';

const Button = ({ btnType, text, doneText = 'added', isDisabled, className, onClick, children, url, customLinkProps, target, type = 'button', size, hasAdd }) => {
    const Component = useMemo(() => (url ? CustomLink : 'button'), [url]);
    const customProps = useMemo(() => (url ? { ...customLinkProps, url, target } : { type }), [url]);

    let button;

    //! State
    const [isAddedBag, setIsAddedBug] = useState(false);

    //! Refs
    const avocado1Ref = useRef();
    const avocado2Ref = useRef();
    const avocado3Ref = useRef();
    const textRef = useRef();

    const handleAddToBag = useCallback(
        (e) => {
            e.preventDefault();

            const timeline = gsap.timeline({ repeat: -1, delay: 0.1 });
            gsap.to([textRef.current], { opacity: 0 });

            timeline.to([avocado1Ref.current, avocado2Ref.current, avocado3Ref.current], { top: '50%', stagger: 0.1 });
            timeline.to([avocado1Ref.current, avocado2Ref.current, avocado3Ref.current], { top: '150%', stagger: 0.1 });
            timeline.to([avocado1Ref.current, avocado2Ref.current, avocado3Ref.current], { top: '-100%', duration: 0.001 });

            onClick(e)
                .finally(() => {
                    if (avocado1Ref.current != undefined && avocado2Ref.current != undefined && avocado3Ref.current != undefined && textRef.current != undefined) {
                        setTimeout(() => {
                            setIsAddedBug(true);
                            gsap.to([avocado1Ref.current, avocado2Ref.current, avocado3Ref.current], { top: '-100%', duration: 0.001, delay: 0.2 }).then(() => {
                                timeline.pause();
                            });
                            gsap.to([textRef.current], { opacity: 1, delay: 0.7 });
                        }, 1000);
                    }
                })
        },
        [onClick, isAddedBag]
    );

    switch (btnType) {
        case 'float-underline':
            button = (
                <ButtonStyle
                    onClick={onClick}
                    className={`${btnType} ${isDisabled ? 'disabled' : ''} ${className || ''}`}>
                    <Component {...customProps}>
                        {children || (
                            <Text
                                className={'h2 font-futura-regular button-text'}
                                text={text}
                            />
                        )}
                    </Component>
                </ButtonStyle>
            );
            break;

        case 'arrow':
            button = (
                <ButtonStyle
                    onClick={onClick}
                    className={`${btnType} ${isDisabled ? 'disabled' : ''} ${className || ''}`}>
                    <Component {...customProps}>{children || <Icon name={'arrow-big'} />}</Component>
                </ButtonStyle>
            );
            break;

        default:
            button = (
                <ButtonStyle
                    onClick={hasAdd ? handleAddToBag : onClick}
                    className={`default ${size || ''} ${className || ''} ${isDisabled ? 'disabled' : ''}`}>
                    <Component {...customProps}>
                        {children || (
                            <Text
                                ref={textRef}
                                text={isAddedBag ? doneText : text}
                                className={'font-futura-medium h5 purple button-text'}
                            />
                        )}

                        {hasAdd && (
                            <>
                                <Image
                                    ref={avocado1Ref}
                                    className={'avocado-1'}
                                    src={'/images/avocado.svg'}
                                />
                                <Image
                                    ref={avocado2Ref}
                                    className={'avocado-2'}
                                    src={'/images/avocado.svg'}
                                />
                                <Image
                                    ref={avocado3Ref}
                                    className={'avocado-3'}
                                    src={'/images/avocado.svg'}
                                />
                            </>
                        )}
                    </Component>
                </ButtonStyle>
            );
            break;
    }
    return button;
};

export default Button;
